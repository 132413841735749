import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import './Home.css';
import Navbar from './component/navbar';
import CameraCapture from './CameraCapture';


export default function Home({ baseUrl, authToken }) {
    const [formState, setFormState] = useState({
        'propertyInputName': '',
        'prp_id': '',
        'assignment_id': '',
        'waste_provided': '',
        'waste_category': [],
        'is_segregate': '',
        'remark': '',
        'reason': ''

    });


    const [propertiesList, setPropertiesList] = useState([]);
    const [filteredProperties, setFilteredProperties] = useState([]);
    const [isSuggestionVisible, setSuggestionVisible] = useState(false);
    const [imageData, setImageData] = useState({ wetfile: null, dryfile: null, sanitaryfile: null, mixedWastefile: null })
    const navigate = useNavigate();

    const fetchProperties = async (lat, lng) => {
        try {
            const response = await fetch(`https://apis.ecowrap.in/b2g/internal-team/api/v1/property?lat=${lat}&lng=${lng}`,
                {
                    method: 'GET',
                    headers: { 'Authorization': `Bearer ${authToken}` }
                });
            const data = await response.json();
            if (data.statusCode === 200) {
                setPropertiesList(data.data);
                setFilteredProperties(data.data);
            } else if (data.statusCode === 401 || data.statusCode === 203) {
                navigate('/login');
            } else {
                throw new Error(response);
            }
        } catch (error) {
            console.error('Error fetching properties:', error);
        }
    };

    const getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    fetchProperties(latitude, longitude);
                },
                (error) => console.error('Error getting location:', error)
            );
        } else {
            console.log('Geolocation is not supported by this browser.');
        }
    };
    useEffect(() => {
        getLocation();
        if (!authToken) {
            navigate('/login');
        }
        const interval = setInterval(() => getLocation(), 60000);
        return () => clearInterval(interval);
    }, [baseUrl, authToken, navigate]);

    const handleChange = (e) => {
        const { name, value, checked } = e.target;

        if (name === 'propertyInputName') {
            setFormState({ ...formState, [name]: value });

            const filtered = propertiesList
                .filter((property) =>
                    property.house_number.toLowerCase().includes(value.toLowerCase())
                )
                .sort((a, b) => {
                    const input = value.toLowerCase();
                    const aMatch = a.house_number.toLowerCase().indexOf(input);
                    const bMatch = b.house_number.toLowerCase().indexOf(input);
                    return aMatch - bMatch;
                });

            setFilteredProperties(filtered);

            const exactMatch = propertiesList.find(property => property.house_number.toLowerCase() === value.toLowerCase());
            if (!exactMatch) {
                setFormState((prevState) => ({
                    ...prevState,
                    assignment_id: '',
                    prp_id: ''
                }));
            }
        } else if (['is_segregate', 'remark', 'waste_provided'].includes(name)) {
            //if waste_provided value turn   to false is_segregate and waste_category also need to change
            if (name === 'waste_provided' && value === 'false') {

                setFormState({ ...formState, 'is_segregate': 'false', 'waste_category': [], [name]: value })
                setImageData({ ...imageData, wetfile: null, dryfile: null, sanitaryfile: null })

            } else if (name === 'waste_provided' && value === 'true') {
                setFormState({ ...formState, 'reason': '', [name]: value })
                setImageData({ ...imageData, mixedWastefile: null })

            } else {
                setFormState({
                    ...formState,
                    [name]: value
                });
            }

        } else if (name === 'waste_category') {
            setFormState((prevState) => {
                const updatedCategories = checked
                    ? [...prevState.waste_category, value]
                    : prevState.waste_category.filter((item) => item !== value);
                return { ...prevState, [name]: updatedCategories };
            });
        } else {
            setFormState({ ...formState, [name]: value });
        }
    };


    const handleFocus = () => {
        setSuggestionVisible(true);
    };
    const handleBlur = () => {
        setSuggestionVisible(false)
    }

    const handleSuggestionClick = (property) => {
        setFormState({
            ...formState,
            propertyInputName: property.house_number,
            assignment_id: property.assignment_id,
            prp_id: property.prp_id
        });
        setSuggestionVisible(false);  // Hide suggestions after click
    };

    let buttonDisable = false;
    const handleSubmit = async () => {
        buttonDisable = true
        try {
            const formData = new FormData();

            const isImageValid = (imageData) => {
                if (!imageData) return false;
                // Convert data URL to a byte array to get its size
                const byteString = atob(imageData.split(',')[1]);
                return byteString.length > 0;
            };

            if (formState.waste_provided === 'true') {

                const appendImage = async (imageData, fieldName, extension) => {
                    if (isImageValid(imageData)) {
                        const blob = await fetch(imageData).then(res => res.blob());
                        // Create a file name with the appropriate extension
                        const fileName = `${fieldName}${extension}`;
                        formData.append(fieldName, new File([blob], fileName, { type: `image/${extension.replace('.', '')}` }));
                        console.log(blob.toString());
                    }
                };

                // Append images with extensions
                await appendImage(imageData.wetfile, 'wetfile', '.png');
                await appendImage(imageData.dryfile, 'dryfile', '.png');
                await appendImage(imageData.sanitaryfile, 'sanitaryfile', '.png');
                const imgs = new Image();
                imgs.src = imageData.wetfile;
                imgs.onload = () => {
                    console.log(imgs.width, imgs.height, "imgs.height width");

                }
            }

            formData.append('prp_id', formState.prp_id)
            formData.append('waste_provided', formState.waste_provided)
            formData.append('assignment_id', formState.assignment_id)
            formData.append('is_segregate', formState.is_segregate)
            formData.append('remark', formState.remark)

            if (formState.waste_provided === 'false') {
                formData.append('reason', formState.reason)
            }
            formData.append('waste_category', JSON.stringify(formState.waste_category))

            const response = await fetch(`https://apis.ecowrap.in/b2g/internal-team/api/v1/rating`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${authToken}`
                },
                body: formData
            });

            const data = await response.json();
            console.log(response);

            if (response.ok && data.statusCode === 200) {

                getLocation()
                Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: 'Form submitted successfully.',
                    confirmButtonText: 'OK'
                });

            } else if (data.statusCode === 401 || data.statusCode === 203) {
                navigate('/login');
            } else {
                throw new Error(response);

            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'There was an error submitting the form. Please try again later.',
                confirmButtonText: 'OK'
            });
            console.error('Error submitting form:', error);

        } finally {
            setFormState({
                'propertyInputName': '',
                'assignment_id': '',
                'waste_provided': '',
                'waste_category': [],
                'is_segregate': '',
                'remark': '',
                'prp_id': '',
                'reason': ''
            });
            setImageData({ wetfile: null, dryfile: null, sanitaryfile: null, mixedWastefile: null })
            buttonDisable = false
        }
    };

    return (
        <>
            {authToken && <div className="container-fluid p-3 d-flex flex-column justify-content-center mb-5">
                <Navbar />

                <div className='row d-flex justify-content-end mt-1'>
                    <button
                        className='col-2 col-sm-1 btn btn-primary'
                        onClick={() => navigate('/survey')}
                        disabled={!(formState.prp_id === '')}
                    >
                        Survey
                    </button>
                </div>
                <div className="row w-100 d-flex flex-column justify-content-center align-items-center mt-5">
                    {/* Property Input */}
                    <div className="form-floating mb-3 col-11 col-sm-8 col-md-5 col-lg-4 p-1 properties-search-input">
                        <input
                            type="text"
                            className="form-control"
                            id="propertyNumber"
                            placeholder="House/Property Number"
                            name="propertyInputName"
                            value={formState.propertyInputName}
                            onChange={handleChange}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            autoComplete="off"
                        />
                        <label htmlFor="propertyNumber">House/Property Number</label>
                        {isSuggestionVisible && (
                            <ul className="suggestions-list w-100 col-11 col-sm-8 col-md-5 col-lg-4">
                                {filteredProperties.length > 0 ? (
                                    filteredProperties.map((property, index) => (
                                        <li
                                            disabled={property?.collection_status === 'Yes'}
                                            key={index}
                                            onMouseDown={() => property?.collection_status === 'Yes' ? 1 : handleSuggestionClick(property)}  // Use onMouseDown to avoid losing focus before click
                                            className={`suggestion-item  d-flex justify-content-between ${property?.collection_status === 'Yes' ? 'text-danger' : ''} `}
                                        >
                                            <div className='me-5'>{property.house_number}</div>
                                            <div className='me-5 ms-5'>{property?.street}</div>
                                            <div className="me-5">
                                                {property?.collection_status === 'Yes' ? <div className='greenDot'></div> : ''}
                                            </div>
                                        </li>
                                    ))
                                ) : (
                                    <li className="no-property-found">No property found</li>
                                )}
                            </ul>
                        )}
                    </div>
                    {/* Waste Provided */}
                    {!(formState.prp_id === '') && <div className="d-flex justify-content-center mb-3 col-11 col-sm-8 col-md-5 col-lg-4">
                        <p className="me-5">Waste provided?</p>
                        <div>
                            <div className="form-check form-check-inline">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    id="wasteProvidedTrue"
                                    name="waste_provided"
                                    value={true}
                                    checked={formState.waste_provided === "true"}
                                    onChange={handleChange}
                                />
                                <label className="form-check-label" htmlFor="wasteProvidedTrue">Yes</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    id="wasteProvidedFalse"
                                    name="waste_provided"
                                    value={false}
                                    checked={formState.waste_provided === "false"}
                                    onChange={handleChange}
                                />
                                <label className="form-check-label" htmlFor="wasteProvidedFalse">No</label>
                            </div>
                        </div>
                    </div>}

                    {/* Waste Category */}
                    {!(formState.prp_id === '') && formState.waste_provided === 'true' && (
                        <div className="d-flex justify-content-center mb-3 col-11 col-sm-8 col-md-5 col-lg-4">
                            <p className="me-5">Waste Category?</p>
                            <div>
                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="wasteCategoryDry"
                                        value="dry"
                                        name="waste_category"
                                        checked={formState.waste_category.includes('dry')}
                                        onChange={handleChange}
                                    />
                                    <label className="form-check-label" htmlFor="wasteCategoryDry">Dry</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="wasteCategoryWet"
                                        value="wet"
                                        name="waste_category"
                                        checked={formState.waste_category.includes('wet')}
                                        onChange={handleChange}
                                    />
                                    <label className="form-check-label" htmlFor="wasteCategoryWet">Wet</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="wasteCategorySanitary"
                                        value="sanitary"
                                        name="waste_category"
                                        checked={formState.waste_category.includes('sanitary')}
                                        onChange={handleChange}
                                    />
                                    <label className="form-check-label" htmlFor="wasteCategoryWet">Sanitary</label>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Segregation */}
                    {!(formState.prp_id === '') && formState.waste_provided === 'true' && (
                        <div className="d-flex justify-content-center mb-3 col-11 col-sm-8 col-md-5 col-lg-4">
                            <p className="me-5">Waste Segregated?</p>
                            <div>
                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        id="segregateTrue"
                                        name="is_segregate"
                                        value={true}
                                        checked={formState.is_segregate === "true"}
                                        onChange={handleChange}
                                    />
                                    <label className="form-check-label" htmlFor="segregateTrue">Yes</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        id="segregateFalse"
                                        name="is_segregate"
                                        value={false}
                                        checked={formState.is_segregate === "false"}
                                        onChange={handleChange}
                                    />
                                    <label className="form-check-label" htmlFor="segregateFalse">No</label>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Reason */}
                    {!(formState.prp_id === '') && formState.waste_provided === 'false' && (
                        <>
                            <div className="d-flex justify-content-center mb-3 col-11 col-sm-8 col-md-5 col-lg-4">
                                <p className="me-5">Reason</p>
                                <div>
                                    <div className="form-check form-check-inline">
                                        <select name="reason" value={formState.reason} className='form-select' onChange={handleChange} >
                                            <option className='p-2' value="">Select Reason</option>
                                            <option className='p-2' value="Mixed Waste"> Mixed Waste </option>
                                            <option className='m-5' value="Owner Don't want to give"> Owner Don't want to give </option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                        </>
                    )}


                    {/* Camera */}
                    {!(formState.prp_id === '') && (formState.waste_provided === 'true' || formState.reason === 'Mixed Waste') &&
                        <div className='d-flex gap-3 flex-wrap'>
                            <CameraCapture imageData={imageData} setImageData={setImageData} name={"wetfile"} />
                            <CameraCapture imageData={imageData} setImageData={setImageData} name={"dryfile"} />
                            <CameraCapture imageData={imageData} setImageData={setImageData} name={"sanitaryfile"} />

                        </div>
                    }

                    {/* Remark */}
                    {!(formState.prp_id === '') && <div className="form-floating col-11 col-sm-8 col-md-5 col-lg-4 p-1">
                        <textarea
                            className="form-control"
                            placeholder="Leave a remark here"
                            id="remark"
                            style={{ height: "100px" }}
                            name="remark"
                            value={formState.remark}
                            onChange={handleChange}
                        />
                        <label htmlFor="remark">Remarks</label>
                    </div>}

                    <button
                        type="button"
                        className="btn btn-primary mt-3 col-11 col-sm-8 col-md-5 col-lg-4"
                        onClick={handleSubmit}
                        disabled={(formState.prp_id === '') || (formState.assignment_id === '') || buttonDisable}
                    >
                        Submit
                    </button>
                </div>
            </div>}
        </>
    );
}
