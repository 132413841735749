import React, { useRef, useState } from 'react';
import Webcam from 'react-webcam';
import Swal from 'sweetalert2';

const CameraCapture = ({ imageData, setImageData, name }) => {
    const webcamRef = useRef(null);
    const canvasRef = useRef(null);
    const [image, setImage] = useState(null);
    const [isCameraOpen, setIsCameraOpen] = useState(true);
    const [isFrontCamera, setIsFrontCamera] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');

    const videoConstraints = {
        width: 350,
        height: 250,
        facingMode: isFrontCamera ? "environment" : "user",
    };

    const handleCameraError = (error) => {
        console.error(error.name + ": " + error.message, "error");
        if (error.name === 'OverconstrainedError' || error.name === 'NotFoundError') {
            Swal.fire({
                title: "Camera Mode Unavailable",
                text: "Selected camera mode is not available. Would you like to switch to the other camera?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Switch Camera",
                cancelButtonText: "Retry"
            }).then((result) => {
                if (result.isConfirmed) {
                    setIsFrontCamera(prev => !prev);
                } else {
                    setTimeout(() => setIsCameraOpen(true), 2000);
                }
            });
        } else if (error.name === 'NotAllowedError') {
            Swal.fire("Access Denied", "Camera access denied. Please enable permissions.", "error");
        } else {
            Swal.fire("Access Failed", "Camera access failed. Please check your device settings.", "error");
        }
    };

    const openCamera = () => {
        if (image) {
            setImage(null);
            setImageData({ ...imageData, [name]: null });
        }
        setIsCameraOpen((prev) => !prev);
    };

    const flipCamera = () => {
        setIsFrontCamera(prev => !prev);
    };

    const captureImage = () => {
        console.log("image capture start");

        const imageSrc = webcamRef.current.getScreenshot();
        const canvas = canvasRef.current;

        if (imageSrc) {
            const img = new Image();
            img.src = imageSrc;

            img.onload = () => {
                const context = canvas.getContext('2d');
                context.clearRect(0, 0, canvas.width, canvas.height);
                context.drawImage(img, 0, 0, 960, 1280);
                const data = canvas.toDataURL('image/png');
                setImage(imageSrc);
                setImageData({ ...imageData, [name]: data });
                setIsCameraOpen(false); // Close camera after capture

                console.log("Captured image resolution:", canvas.width, "x", canvas.height, img.height);
            };

            img.onerror = () => {
                console.log("Image loading error");
            };
        }
    };

    const deleteImage = () => {
        setImage(null);
        setImageData({ ...imageData, [name]: null });
        const canvas = canvasRef.current;
        if (canvas) {
            const context = canvas.getContext('2d');
            context.clearRect(0, 0, canvas.width, canvas.height);
        }
    };

    return (
        <div className="container mt-4" style={{ width: "400px" }}>
            <h1 className="text-center">{name.toUpperCase()} Capture</h1>
            <div className="text-center mb-3">
                <button className="btn btn-primary" onClick={openCamera}>
                    {isCameraOpen ? "Close Camera" : "Open Camera"}
                </button>
                {isCameraOpen && (
                    <button className="btn btn-secondary ms-2" onClick={flipCamera}>
                        Flip Camera
                    </button>
                )}
            </div>

            {errorMessage && (
                <div className="alert alert-danger text-center">{errorMessage}</div>
            )}

            {isCameraOpen && (
                <div className="d-flex flex-column align-items-center">
                    <Webcam
                        audio={false}
                        ref={webcamRef}
                        screenshotFormat="image/png"
                        videoConstraints={videoConstraints}
                        onUserMediaError={handleCameraError}
                        className="border rounded mb-3"
                    />
                    <button className="btn btn-secondary mb-2" onClick={captureImage}>Capture Image</button>
                    <canvas ref={canvasRef} width="960" height="1280" style={{ display: 'none' }}></canvas>
                </div>
            )}

            {image && (
                <div className="text-center">
                    <img src={image} alt="Captured" className="img-fluid mb-2" />
                    <button className="btn btn-danger" onClick={deleteImage}>Delete Image</button>
                </div>
            )}
        </div>
    );
};

export default CameraCapture;
